<template>
	<div ref="fade">
		<transition name="fade">
			<div
				v-if="showRightEdge"
				:style="{ 'width': `${gradientWidth}px`, 'height': `${fadeHeight}px` }"
				:class="[
					'absolute right-0 block h-auto pointer-events-none bg-gradient-to-l opacity-100',
					gradientColorFrom
				]"
			/>
		</transition>
		<transition name="fade">
			<div
				v-if="showLeftEdge"
				:style="{ 'width': `${gradientWidth}px`, 'height': `${fadeHeight}px` }"
				:class="[
					'absolute left-0 block h-auto pointer-events-none bg-gradient-to-r opacity-100',
					gradientColorFrom
				]"
			/>
		</transition>
	</div>
</template>

<script async>
export default {
	props: {
		parentRefName: {
			type: String,
			required: true
		},
		scrollSpied: {
			type: Number,
			required: true
		},
		gradientWidth: {
			type: Number,
			default: 20
		},
		gradientColorFrom: {
			type: String,
			default: 'from-white'
		},
		fadeUseRowItemHeight: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			showRightEdge: false,
			showLeftEdge: false
		}
	},
	computed: {
		parentRef() {
			return this.$parent.$refs[this.parentRefName]
		},
		parentIsXScrollable() {
			return this.parentRef?.classList.contains('overflow-x-auto') || this.parentRef?.classList.contains('overflow-x-scroll')
		},
		parentHeight() {
			return this.parentRef?.offsetHeight
		},
		innerHeight() {
			return this.$parent?.$refs?.[this.parentRefName].children?.[0]?.clientHeight
		},
		fadeHeight() {
			return this.fadeUseRowItemHeight ? this.innerHeight : this.parentHeight
		}
	},
	watch: {
		scrollSpied() {
			this.calculatePosition()
		},
		$route() {
			this.calculatePosition()
		}
	},
	mounted() {
		this.calculatePosition()
	},
	methods: {
		calculatePosition() {
			const parentScrollLeftPosition = this.parentRef?.scrollLeft
			const xScrollWidth = this.parentRef?.scrollWidth
			const parentWidth = this.parentRef?.offsetWidth
			const rightEdge = parentScrollLeftPosition + parentWidth
			const leftEdge = parentScrollLeftPosition
			const contentWidth = rightEdge

			if (this.parentIsXScrollable) {
				if (contentWidth < xScrollWidth) {
					this.showRightEdge = true
				} else {
					this.showRightEdge = false
				}
				if (leftEdge > 0) {
					this.showLeftEdge = true
				} else {
					this.showLeftEdge = false
				}
			}
		}
	}
}
</script>

<style scoped>
.fade-enter-active {
  transition: all .2 ease;
}
.fade-leave-active {
  transition: all .125s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
</style>
