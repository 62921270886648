var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"fade"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.showRightEdge)?_c('div',{class:[
				'absolute right-0 block h-auto pointer-events-none bg-gradient-to-l opacity-100',
				_vm.gradientColorFrom
			],style:({ 'width': `${_vm.gradientWidth}px`, 'height': `${_vm.fadeHeight}px` })}):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.showLeftEdge)?_c('div',{class:[
				'absolute left-0 block h-auto pointer-events-none bg-gradient-to-r opacity-100',
				_vm.gradientColorFrom
			],style:({ 'width': `${_vm.gradientWidth}px`, 'height': `${_vm.fadeHeight}px` })}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }